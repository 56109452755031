import {apiEndpoint} from "../common/open2web3";
import {SET_LOADING} from "../reducer/Main.reducer";


class PriceServices{


    constructor() {
    }


/*
/services/myExternalPayment/convertBusdToSwu?BUSDAmount=100
methode GET
param  : BUSDAmount : String : montant Busd a convertir en swu 

resultat json : exemple :
{
        "swu": 2500, // montant swu selon le cours
        "fees": 25,  // platform fees
        "swuToReceive": 2475 // swu - fees
}

auth : everyone 
*/
    getSWURate(usdAmount, currency, anonymous = false, abortController = null){
        return new Promise(async (resolve, reject) => {
            let url = anonymous ? '/services/myExternalPayment/amountToReceiveUserNotConnected' : '/services/myExternalPayment/amountToReceive'
            fetch(apiEndpoint + url + "?amountToInvest=" + usdAmount + "&currency=" + currency, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                hasAuth: !anonymous,
                signal: abortController?.signal 
            })
                .then(response => response.json())
                .then(data => {
                   return resolve({
                       ...data,
                       amount : parseFloat(data.swuToReceive).toFixed(4) * 1,
                       //affiliateBonus: parseFloat(data.fees),
                       affiliateBonus: 0,
                       foundationTax: parseFloat(data.fees).toFixed(4) * 1,
                   })
                }).catch(err => {
                console.error(err);
            }).catch(err => {
                console.error(err);
            });
        });
    }

    sendTxId = (id, txID) => {
        const url = `/services/myExternalPayment/transactionId?id=${id}&transactionid=${txID}`;
        return new Promise((resolve) => {
            fetch(apiEndpoint + url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                hasAuth: true,
                body: JSON.stringify({
                    id: id,
                    transactionid: txID
                })
            })
                //.then(response => response.json())
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    resolve(false);
                });
        });
    }

    getMyBalance(username){
        return new Promise(async (resolve, reject) => {
            fetch(apiEndpoint + "/services/LabarakIcoDB/queryExecutor/queries/selectBUSDSWUAmountfromexternalpayment")
                .then(response => response.json())
                .then(data => {
                    let vars = data;
                    return resolve({
                        totalBalance: data.content.reduce((p,c)=>p+c.sum_swuamount_, 0),
                        balances: data
                    })
                }).catch(err => {
                console.error(err);
            }).catch(err => {
                console.error(err);
            });
        });
    }
}


export default new PriceServices();
