import { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import WalletConnectButton from '../common/WalletConnectButton';
import KYCConnectButton from '../common/KYCConnectButton';
import { Modal, Spin } from 'antd';
import { apiEndpoint, blockExplorerUrl } from '../common/open2web3';
import AntBoxInfo from '../common/AntBoxInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMediumM, faInstagram, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { Trans, withTranslation } from 'react-i18next';
import AuthUtils from '../services/authService';
import termsURLFr from "../documents/Termes-ET-Conditions_FR.pdf";
import termsURLEn from "../documents/Termes-ET-Conditions_EN.pdf";
import NoticeURLFr from "../documents/mentions-legales_FR.pdf";
import NoticeURLEn from "../documents/mentions-legales_EN.pdf";
import i18n from '../i18n';
import HasParent from '../common/HasParent';

class Layout extends Component {

  state = {
    isMobile: false
  }

  componentDidMount() {

    var isMobile = false;
    if (navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      isMobile = true
    } else {
      isMobile = false
    }
    this.setState({ isMobile: isMobile });
  }

  changeLang = (lang) => () => {
    if(this.props.i18n.language === lang) return;
    this.props.i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    if(AuthUtils.isConnected()){
      fetch(`${apiEndpoint}/services/myUsers/loggedInUserlanguage?lang=${lang.toUpperCase()}`,{
        method: 'PUT',
      }).catch(error => console.log(error));
    }
    let pathUrl = window.location.pathname.split("/")
    var redirectPath = "/" + lang + "/" + (pathUrl[2]=="" ? pathUrl[2] : 'token-sale')
    //window.location.href = redirectPath;
    this.props.history.push(redirectPath)
  }



  render() {
    const connected = !!this.props.store.walletAddress || (!!this.props.store?.user?.username && this.props.store?.walletConnected);
    const isNotOwner = !this.props.store.owner || !this.props.store.walletAddress || this.props.store.owner !== this.props.store.walletAddress;
    const whitepapierUrl = i18n.language === "fr" ? "https://quertix.com/quertix_whitepaper_fr/":"https://quertix.com/quertix_whitepaper_eng/";

    /*const termsURL = this.props.i18n.language === "fr" ?
      "https://end.quertix.io/services/file/downloadFile?file=Termes-ET-Conditions_FR.pdf&amp;returnName=Termes-ET-Conditions_FR.pdf ":
      "https://end.quertix.io/services/file/downloadFile?file=Termes-ET-Conditions_EN.pdf&amp;returnName=Termes-ET-Conditions_EN.pdf ";
    
   const NoticeURL = this.props.i18n.language === "fr" ?
      "https://end.quertix.io/services/file/downloadFile?file=mentions-legales_FR.pdf&amp;returnName=mentions-legales_FR.pdf":
      "https://end.quertix.io/services/file/downloadFile?file=mentions-legales_EN.pdf&amp;returnName=mentions-legales_EN.pdf";
    */

      return (
      <>
        <Spin spinning={this.props.store.loading && this.props.location.pathname !== '/'}>
          <main>
            <div className="top-nav">

              <nav className="navbar navbar-inverse">
                <div className="container-fluid pb-30">
                  <div className="navbar-header navHeaderM" style={{marginTop: "-5px" }}>
{connected && <button type="button" className="navbar-toggle collapsed" onClick={()=>this.setState({showMenu:!this.state.showMenu})}>
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>  }
                    
                    {this.state.showMenu ? (
                      <div className="menuMobile" style={{height:'100%'}}>
                        <div className="headerMenu" style={{height:'8%'}}>
                        <button onClick={()=>this.setState({showMenu:!this.state.showMenu})}>x</button>
                        <img src="/images/logo_mobile.svg" className="logo" alt="Quertix ICO" style={{maxHeight:42}}/>

                        </div>
                        <div className="unitS" style={{height:'20%'}}>
                          <span> {this.props.store.walletAddress ?
                        <a target="_blank" rel="noopener noreferrer" href={`${blockExplorerUrl}/address/${this.props.store.walletAddress}`}>{this.props.store.walletAddressShortForm}</a>
                        : null}</span>
                        
                          <span><img src="/images/beepbb.svg"  width="22" alt="Quertix ICO" style={{height:25}}/><span className="priceS"><AntBoxInfo.Price valueOnly /></span></span>
                         {/* <span>Soft Cap : 500k €</span>
                          <span>Hard Cap : 4M €</span>*/} 
                        </div>
                        <div style={{height:'45%'}}>
                        <ul className="navMo">
                      {/*<li className={this.props.location.pathname === '/' ? 'active' : ''} ><Link to="/"> <span className="glyphicon glyphicon-th-large"></span>  <Trans i18nKey="Dashboard" /></Link></li>*/}
                      {connected  && isNotOwner ?  <li onClick={()=>this.setState({showMenu:!this.state.showMenu})} className={this.props.location.pathname === "/"+this.props.i18n.language+'/token-sale' ? 'active' : ''}><Link
                          to={"/"+this.props.i18n.language+"/token-sale"}> <div class="logoAchatM"></div> <Trans i18nKey="Token Sale"/></Link></li> : null}
                     {connected && isNotOwner ? <li onClick={()=>this.setState({showMenu:!this.state.showMenu})} className={this.props.location.pathname === "/"+this.props.i18n.language+'/referral' ? 'active btnaffM' : 'btnaffM'} ><Link to={"/"+this.props.i18n.language+"/referral"}> <div class="logoaffM"></div> <Trans i18nKey="Referral" /><div><HasParent /></div></Link></li> : null}
                      {connected && isNotOwner ? <li onClick={()=>this.setState({showMenu:!this.state.showMenu})} className={this.props.location.pathname === "/"+this.props.i18n.language+'/myProfile' ? 'active btnfeuM' : 'btnfeuM'} ><Link to={"/"+this.props.i18n.language+"/myProfile"}> <div class="logofeuM"></div> <Trans i18nKey="My Wallet" /></Link></li> : null}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === '/whitepaper' ? 'active  btnwhiteM' : 'btnwhiteM'} ><a href={whitepapierUrl} rel='noopener noreferrer' target="_blank"> <span className="glyphicon glyphicon-road pr-5"></span><Trans i18nKey="Whitepaper" /></a></li> : null }

                      {/*<li className={this.props.location.pathname === '/whitepaper' ? 'active' : ''} ><Link to="/whitepaper"> <span className="glyphicon glyphicon-road pr-5"></span> <Trans i18nKey="White Paper" /></Link></li>*/}
                      {/* <li className={this.props.location.pathname === '/vote' ? 'active' : ''} ><Link to="/vote"> <span className="glyphicon glyphicon-inbox"></span> Vote</Link></li> */}
                      {/* <li className={this.props.location.pathname === '/properties' ? 'active' : ''} ><Link to="/properties"> <span className="glyphicon glyphicon-stats"></span> Properties</Link></li> */}
                      {isNotOwner ? null : <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/admin' ? 'active' : ''} ><Link to={"/"+this.props.i18n.language+"/admin"}> <span className="glyphicon glyphicon-th-large"></span> <Trans i18nKey="Admin" /></Link></li>}
                      

                     
                      
                    </ul>
                        </div>
                        <div className="footerMenu">
                        <div style={{display:'flex'}}><WalletConnectButton closeMobileMenu={ ()=>this.setState({showMenu: false})}/></div>
                          </div>

                        </div>
                    ):null}
                    <a href="#"><img style={{ maxwidth: 216, marginRight: 5, height: 32, width: 'initial', marginTop:5 }} src="/images/logo_mobile.svg" alt="Quertix ICO" className="dM-none logoQ" /></a>
                    {/*partie mobile*/}

                    <ul className="navM dL-none dipad-none">
                      <li><img src="/images/logomobi.png" className="logo" alt="Quertix ICO" style={{paddingRight:15}} /></li>
                      <li style={{display:'flex',gap:25}} className="walletC"><WalletConnectButton /></li>
                      <li onClick={this.changeLang('fr')}><img src="/images/fr.png" width="22"/></li>
                      <li onClick={this.changeLang('en')}><img src="/images/en.png" width="22"/></li>
                      <ul>
          
        </ul>
                      

                      {connected && <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/token-sale' ? 'active btntoken' : 'btntoken'}><Link
                          to={"/"+this.props.i18n.language+"/token-sale"}> <img src="/images/beepbb.svg" style={{marginTop:-3 ,width:24}} /> <Trans
                          i18nKey="Token Sale"/></Link></li>}
                     
                    </ul>


                  </div>

                  <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav nav1" style={{paddingTop: 3}}>
                      {/*<li className={this.props.location.pathname === '/' ? 'active' : ''} ><Link to="/"> <span className="glyphicon glyphicon-th-large"></span>  <Trans i18nKey="Dashboard" /></Link></li>*/}
                      {connected && <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/token-sale' ? 'active btntoken' : 'btntoken'}><Link
                          to={"/"+this.props.i18n.language+"/token-sale"}><div class="logoAchat"></div> <Trans i18nKey="Token Sale"/></Link></li>}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/referral' ? 'active btnaff' : 'btnaff'} ><Link to={"/"+this.props.i18n.language+"/referral"}><div class="logoaff"></div> <Trans i18nKey="Referral" /><div><HasParent /></div></Link></li> : null}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/myProfile' ? 'active btnfeu' : 'btnfeu'} ><Link to={"/"+this.props.i18n.language+"/myProfile"}><div class="logofeu"></div><Trans i18nKey="My Wallet" /></Link></li> : null}
                      {connected && isNotOwner ? <li className={this.props.location.pathname === '/whitepaper' ? 'active  btnwhite' : 'btnwhite'} ><a href={whitepapierUrl} rel='noopener noreferrer' target="_blank"> <span className="glyphicon glyphicon-road pr-5"></span> <Trans i18nKey="Whitepaper"/></a></li> : null }
                      {/*connected && <HasParent/>*/}
                      {/* <li className={this.props.location.pathname === '/vote' ? 'active' : ''} ><Link to="/vote"> <span className="glyphicon glyphicon-inbox"></span> Vote</Link></li> */}
                      {/* <li className={this.props.location.pathname === '/properties' ? 'active' : ''} ><Link to="/properties"> <span className="glyphicon glyphicon-stats"></span> Properties</Link></li> */}
                      {isNotOwner ? null : <li className={this.props.location.pathname === "/"+this.props.i18n.language+'/admin' ? 'active' : ''} ><Link to={"/"+this.props.i18n.language+"/admin"}> <span className="glyphicon glyphicon-th-large"></span> <Trans i18nKey="Admin" /></Link></li>}
                      
                      <li><Link to={"/"+this.props.i18n.language+"/token-sale"} ><span className="BtnH hiddenL d-none"><Trans i18nKey="Buy tokens" /></span></Link></li>

                     
                      
                    </ul>

                    {/*partie Desktop*/}
                    <ul className="nav navbar-nav nav2 navbar-right d-sm-none d-md-block">
                      {/* <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        FR
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">Français</a>
                        <a className="dropdown-item" href="#">Anglais</a>
                        <a className="dropdown-item" href="#">Tailand</a>
                        <a className="dropdown-item" href="#">Arabe</a>
                      </div>
                    </li> */}


{/* <li className="dM-none"><a className="Cd"><span>Soft Cap : 500k €</span></a></li>
                      <li className="dM-none"><a className="Cd"><span>Hard Cap : 4M €</span></a></li>*/}

                      
                      <li className="dM-none"><a><span><img src="/images/beepbb.svg" alt="Quertix ICO" width="22" /><span className="priceS"><AntBoxInfo.Price valueOnly /></span></span></a></li>
                 


                      <li className="positionB dM-none d-none"> <Link to={"/"+this.props.i18n.language+"/token-sale"}><span className="BtnH"><Trans i18nKey="Buy tokens" /></span></Link></li>








                      {this.props.store.walletAddress ?
                        <li><a target="_blank" rel="noopener noreferrer" href={`${blockExplorerUrl}/address/${this.props.store.walletAddress}`}>{this.props.store.walletAddressShortForm}</a></li>
                        : null}
                      {!this.props.store.walletAddress && this.props.store.user?.username ?
                          <li><a target="_blank" rel="noopener noreferrer" >{this.props.store.user?.username?.split('@')[0]}</a></li>
                          : null}
                      <li className="connIns btntoken2 btnaff2"><WalletConnectButton /></li>
                      <li><SelectLang params={{changeLang:this.changeLang,store:this.props.store}} /></li>

                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <KYCConnectButton />
            <div className="content">
              {this.props.children}
            </div>
            <footer>
              <div className="sFooter">  
                <span className="copy_rigth">&copy; Copyright Quertix 2022 All Rights Reserved </span>
              <span className="socials-media-icons">

                {/* <a href="#" target="_blank"><img src="/images/imgfb.png" /></a> */}
                {/* <a href="#" target="_blank"><img src="/images/imginst.png" /></a> */}
                <div className="imgSo">
                <a href="https://twitter.com/Quertix_Com" target="_blank"><img src="/images/imgtwi.png" /></a>
                <a href={ this.props.i18n.language === "fr" ?"https://t.me/+LsDHqosuojAyODg0":"https://t.me/quertixworld"} target="_blank"><img src="/images/telegram.png"  style={{width:18}}/></a> 
                <a href="https://www.linkedin.com/company/quertix/" target="_blank"><img src="/images/link.png" /></a>
                </div>
                <div><a href="mailto:support@quertix.io">support@quertix.io</a></div>
            
              </span>
              <div>
                <a className="t_and_c" href={this.props.i18n.language === "fr"?termsURLFr:termsURLEn} rel='noopener noreferrer' target="_blank"><Trans i18nKey="Terms and conditions" /></a>
                <a  className="t_and_c" href={this.props.i18n.language === "fr"?"https://quertix.com/mentions-legales":"https://quertix.com/en/legal-notice/"} rel='noopener noreferrer' target="_blank"><Trans i18nKey="Legal Notice" /></a>
              </div>
              </div>
      

       
            </footer>
          </main>
        </Spin>


        <Modal className="colorT" visible={this.state.isMobile} title="Information" footer={<div style={{ display: 'flex', justifyContent: 'center' }}><button className="BtnH" onClick={e => this.setState({ isMobile: false })} >Ok</button></div>} onOk={e => this.setState({ isMobile: false })} onCancel={e => this.setState({ isMobile: false })} >
          <p><Trans i18nKey="For investment, we recommend using Firefox or Chrome on your computer" /></p>

        </Modal>
      </>
    )
  }
}

function SelectLang(props){
  const connected = !!props.params.store.walletAddress || (!!props.params.store?.user?.username && props.params.store?.walletConnected);
 
  const lang = window.localStorage.getItem("lang");
  return(
    <div className="SelectLangue">
   
      <span>{connected ? <img src="/images/langb.png"  width="24" className="iconlang dM-none"/>:<><img src="/images/langb.png"  width="24" className="iconlang"/></> }
      <img src="/images/langb.png"  className="iconlang langb dL-none"/>
      </span>
      <div className="SelectLangue-items">
        <ul>
          <li onClick={props.params.changeLang('fr')}><img src="/images/fr.png" />Francais</li>
          <li onClick={props.params.changeLang('en')}><img src="/images/en.png" />Anglais</li>
        </ul>

      </div>


    </div>
  )
}


const mapStateToProps = (store) => ({ store });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Layout)));
