import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { apiEndpoint } from "./open2web3";

function HasParent(props){
    const {t} = useTranslation();
    const [data, setData] = useState({});

    useEffect( () =>{
        fetch(apiEndpoint + "/services/myExternalPayment/connectedUserHasParent")
        .then(response => {
            if(!response.ok){
                throw new Error('Something went wrong');
            }
            return response.json()
        })
        .then(json => setData(json));
    },[]);

    return (
        data.hasParent ? 
        <small className="bonuss">
            <Trans i18nKey="parentBonus">{{pourcentage:data.pourcentage}}
            </Trans>
        </small> :
        <div></div>
    );
}

export default HasParent;