import { Component } from 'react'
import Spinner from 'react-spinner-material';
class Loading extends Component {

    render() {
        return (

            <div className="loadingpage">
                <img style={{ height: 220, marginBottom: 32 }} src="/images/LogoNB.png" alt="Quertix ICO" />
                <div className="prog">
                    <div className="slider">
                        <div className="line"></div>
                        <div className="subline inc"></div>
                        <div className="subline dec"></div>
                    </div>
                </div>
            </div>


        )
            ;
    }
}
export default Loading;