import Web3 from 'web3';
import { CrowdsaleAbi, WhitelistAbi, Erc20TokenAbi } from '../contractsAbis';
import Open2web3Provider from './open2web3Provider';
import Web3Modal from "web3modal";
import { rpc_url } from '../open2web3';
//import HDWalletProvider from "@truffle/hdwallet-provider";

class Open2web3Services {
  static Open2web3Provider = Open2web3Provider;

  constructor(options = {}) {
    if ('addressCrowdfounding' in options) {
      this._address = options.addressCrowdfounding;
    }
    if ('addressDAI' in options) {
      this._addressDAI = options.addressDAI;
    }
    if ('adressToken' in options) {
      this._adressToken = options.adressToken;
    }
    if ('adressWhiteList' in options) {
      this._adressWhiteList = options.adressWhiteList;
    }
  }

  setAddress = (address) => {
    this._address = address;
  }

  getContractCrowdsale = (provider) => {
    return this.getContract(provider, CrowdsaleAbi, this._address);
  }
  getContractWhiteList = (provider) => {
    return this.getContract(provider, WhitelistAbi, this._adressWhiteList);
  }
  getContractToken = (provider) => {
    return this.getContract(provider, Erc20TokenAbi, this._adressToken);
  }
  getContractDAI = (provider) => {
    return this.getContract(provider, Erc20TokenAbi, this._addressDAI);
  }

  getContract = (provider, abi, address) => {
    const web3 = new Web3(window.ethereum);
    return new web3.eth.Contract(abi, address);
  };

  getAmountToRecieveFromContract = async (valueToSend, hasReferral) => {
    var value = await this.contract.methods.getTokenToReceive(this.web3.utils.toWei(valueToSend, "ether"), hasReferral).call();
    return {
      valueToReceive: this.web3.utils.fromWei(value[0], "ether"),
      fastBonusValue: this.web3.utils.fromWei(value[1], "ether")
    };
  };

  getTokenAmount = async (valueToSend) => {
    var value = await this.contract.methods.getTokenAmount(this.web3.utils.toWei(valueToSend, "ether")).call();
    return this.web3.utils.fromWei(value, "ether");
  };

  getPlatformFeesAmount = async (valueToSend) => {
    var value = await this.contract.methods.getPlatformFeesAmount(this.web3.utils.toWei(valueToSend, "ether")).call();
    return this.web3.utils.fromWei(value, "ether");
  };
  getFastBonusAmountToSubtract = async (valueToSend) => {
    var value = await this.contract.methods.getFastBonusAmountToSubtract(this.web3.utils.toWei(valueToSend, "ether")).call();
    return this.web3.utils.fromWei(value, "ether");
  };

  buyTokens = (beneficiary, value, parent) => {
    return this.contract.methods.buyTokens(beneficiary, this.web3.utils.toWei(value, "ether"), parent).send({
      from: beneficiary
    });

  }

  transferBlocked = () => {
    return this.getContractToken(this.virtualPovider).methods._transferBlocked().call();
  }

  setTransferBlocked = (blockedStatus, account) => {
    return this.contractToken.methods.setTransferBlocked(blockedStatus).send({
      from: account
    });
  }

  approveDAI = (account, spender, value) => {
    return this.contractDAI.methods.approve(spender, this.web3.utils.toWei(value, "ether")).send({
      from: account
    });
  }

  approveToken = (account, spender, value) => {
    return this.contractToken.methods.approve(spender, this.web3.utils.toWei(value, "ether")).send({
      from: account
    });
  }

  options = {
    timeout: 100000, // ms

    // Useful for credentialed urls, e.g: ws://username:password@localhost:8546
    /*headers: {
      authorization: 'Basic username:password'
    },*/

    clientConfig: {
      // Useful if requests are large
      maxReceivedFrameSize: 100000000,   // bytes - default: 1MiB
      maxReceivedMessageSize: 100000000, // bytes - default: 8MiB

      // Useful to keep a connection alive
      keepalive: true,
      keepaliveInterval: 60000 // ms
    },

    // Enable auto reconnection
    reconnect: {
      auto: true,
      delay: 50000, // ms
      maxAttempts: 5,
      onTimeout: false
    }
  };

  wsProvider = rpc_url
  //wsProvider = new Web3.providers.WebsocketProvider(rpc_url,this.options)

  /*virtualPovider = new HDWalletProvider({
    mnemonic: {
      phrase: "stone picture more humor drift swear slender tape quantum citizen era task paddle swear silver"
    },
    //providerOrUrl: "https://apis.ankr.com/d057aca859fa4e26b1e8b57cd3c98bad/30fd0fd60dc19a950310dada8e0259a9/binance/full/test"
    providerOrUrl: this.wsProvider
  });*/

  virtualPovider = this.wsProvider

  getVirtualWeb3() {
    return new Web3(this.virtualPovider);
  }

  balanceOf = (account) => {
    /*const virtualWeb3 = new Web3(this.virtualPovider);
    return new Promise(async (resolve, reject) => {
      this.getContractToken(this.virtualPovider).methods.balanceOf(account).call().then(balance => {
        resolve(parseFloat(virtualWeb3.utils.fromWei(balance, "ether")).toFixed(2));
      });
    })*/
    return new Promise(async (resolve, reject) => {
      resolve(0);
    })

  };

  balanceOfPayementToken = (account) => {
    /*const virtualWeb3 = new Web3(this.virtualPovider);
    return new Promise(async (resolve, reject) => {
      this.getContractDAI(this.virtualPovider).methods.balanceOf(account).call().then(balance => {
        resolve(parseFloat(virtualWeb3.utils.fromWei(balance, "ether")).toFixed(2));
      });
    })*/

    return new Promise(async (resolve, reject) => {
      resolve(0);
    })

  };

  totalSupply = () => {
    /*const virtualWeb3 = new Web3(this.virtualPovider);
    return new Promise(async (resolve, reject) => {
      this.getContractToken(this.virtualPovider).methods.totalSupply().call().then(totalSupply => {
        console.log("totalSupply", totalSupply)
        resolve(virtualWeb3.utils.fromWei(totalSupply, "ether"));
      });
    });*/
    return new Promise(async (resolve, reject) => {
      resolve(0);
    })
  };

  weiraised = () => {
    //return this.getContractCrowdsale(this.virtualPovider).methods.weiRaised().call()
    return new Promise(async (resolve, reject) => {
      resolve(0);
    })
  };

  getRate = () => {
    //return this.getContractCrowdsale(this.virtualPovider).methods.rate().call()
    return new Promise(async (resolve, reject) => {
      resolve(0);
    })
  };


  contractDeployedEvent = (contractAddress, fromBlock, toBlock) => {
    /*const virtualWeb3 = new Web3(rpc_url);

    const contractVirtual = new virtualWeb3.eth.Contract(CrowdsaleAbi, contractAddress)
    return new Promise(async (resolve, reject) => {

      contractVirtual.events.ContractDeployed({
        filter: {},
        fromBlock: fromBlock,
        toBlock: toBlock
      }, function (error, event) {
        console.log("ContractDeployed error", error);
        console.log("ContractDeployed", event);
        resolve(event.returnValues);
      })
    })*/
    return new Promise(async (resolve, reject) => {
      resolve(0);
    })
  }

  isWhitelisted = (account) => {
    if (account != null) {
      //return this.getContractWhiteList(this.virtualPovider).methods.isWhitelisted(account).call()
    }
  }

  symbol = () => {
    //return this.getContractToken(this.virtualPovider).methods.symbol().call()
  }

  decimals = () => {
    //return this.getContractToken(this.virtualPovider).methods.decimals().call()
  }

  callContractToken = (fnName, ...args) => {
    return this.getContractToken(this.virtualPovider).methods[fnName](...args).call();
  }

  contractEventListner = (contractAbi, contractAddress, callback) => {

    /*const virtualWeb3 = new Web3(rpc_url);

    const contractVirtual = new virtualWeb3.eth.Contract(contractAbi, contractAddress);
    return contractVirtual.events.allEvents({ fromBlock: 'latest' }, callback);*/

  }

  getCrowdSaleContractAbi = () => {
    return CrowdsaleAbi;
  }
  getWhitelistContractAbi = () => {
    return WhitelistAbi;
  }
  getTokenContractAbi = () => {
    return Erc20TokenAbi;
  }

  static initWeb3(provider) {
    const web3 = new Web3(provider);
    web3.eth.extend({
      methods: [
        {
          name: "chainId",
          call: "eth_chainId",
          outputFormatter: web3.utils.hexToNumber
        }
      ]
    });
    return web3;
  }

  connect = async ({ providerOptions, cacheProvider = true }) => {
    this.web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider,
      providerOptions
    });
    this.provider = await this.web3Modal.connect();
    this.web3 = Open2web3Services.initWeb3(this.provider);
    this.accounts = await this.web3.eth.getAccounts();
    this.address = this.accounts[0];
    this.contract = new this.web3.eth.Contract(CrowdsaleAbi, this._address);
    this.contractToken = new this.web3.eth.Contract(Erc20TokenAbi, this._adressToken);
    this.contractDAI = new this.web3.eth.Contract(Erc20TokenAbi, this._addressDAI);
    this.contractWhiteList = new this.web3.eth.Contract(WhitelistAbi, this._adressWhiteList);
    this.networkId = await this.web3.eth.net.getId();
    this.networkType = await this.web3.eth.net.getNetworkType();
    //this.peerCount = await this.web3.eth.net.getPeerCount();
    this.chainId = await this.web3.eth.getChainId();
    const obj = {
      web3: this.web3,
      contract: this.contract,
      contractToken: this.contractToken,
      contractDAI: this.contractDAI,
      contractWhiteList: this.contractWhiteList,
      provider: this.provider,
      address: this.address,
      chainId: this.chainId,
      networkId: this.networkId
    };
    return obj;
  }
}

export { Open2web3Provider, Open2web3Services }
export default Open2web3Services;
