import ApolloClient, { gql } from "apollo-boost";
import get from 'lodash/get';

class Open2web3Provider{
  icoClient = null;
  tokenClient = null;
  constructor (options = {}) {
    if ('icoClient' in options) {
      this.icoClient = new ApolloClient(options.icoClient);
    }

    if ('tokenClient' in options) {
      this.tokenClient = new ApolloClient(options.tokenClient);
    }

    if ('whitelistedClient' in options) {
      this.whitelistedClient = new ApolloClient(options.whitelistedClient);
    }
  }

  getTransactionsList = gql`
  query transactionsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
      transferEvents(first: $limit, skip: $offset , orderBy: $orderBy,orderDirection: $orderDirection) {
          id
          transaction
          sender
          destination
          amount
          timestamp
      }
      tokens{
      transferEventCount
    }
  }`;
  
  getBalancesList = gql`
  query balancesList($offset:Int!,$limit:Int!){
    accounts(where: {address_not_contains: "0x0000000000000000000000000000000000000000"}) {
              id
              address
              balances {
                amount
              }
            }
          
  }`;
  
  getIcoInfoQuery = gql`
  query icoInfo{
    icos {
      id
      openingTime
      closingTime
      rate
    }
  }`;

  getIcoInfo = () => {
    return this.icoClient
      .query({ query: this.getIcoInfoQuery })
      .then((res) => ({ data: res.data.icos }));
  }

  _getTokenInfos = gql`
    {
      tokens(first: 1) {
        id
        name
        symbol
        totalSupply
      }
    }
  `;

  // Total des tokens émis
  getTokenInfos = () => {
    return this.tokenClient
      .query({ query: this._getTokenInfos })
      .then((res) => ({ ...res.data.tokens.shift() }));
  }

  _getBalanceByAccount = `
    {
      accounts(where: {address: "{account}"}, first: 1) {
        id
        address
        balances {
          amount
        }
      }
    }
  `;

  // Mon solde
  getBalanceByAccount = (account) => {
    return this.tokenClient
      .query({ query: gql`${this._getBalanceByAccount.replace("{account}", account)}` })
      .then((res) => get(res, 'data.accounts[0].balances[0].amount', 0));
  }


  _getIcosGraph = gql`
    {
      icos {
        id
        openingTime
        closingTime
        rate
      }
    }
  `;

  getIcosGraph = () => {
    return this.icoClient
      .query({ query: this._getIcosGraph })
      .then((res) => get(res, 'data.icos[0]', {}));
  }

  _isWhitelisted = `
    {
      whitelisteds(where: {account: "{account}"}, first: 1) {
        id
        account
      }
    }
  `;

  isWhitelisted = (account) => {
    return this.whitelistedClient
      .query({ query: gql`${this._isWhitelisted.replace("{account}", account)}` })
      .then((res) => get(res, 'data.whitelisteds[0]', {}));
  }

}


export default Open2web3Provider;