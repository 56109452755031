function AntBox(props){
    return (
        <div className={`AntBoxInfo AntBoxInfo-${props.type}`}>       
            <div>
            <h3>{props.value} {props.descripiron}</h3>
            <span>{props.title}</span>
            </div>
      </div>
    );
}

export default AntBox;