import { connect } from 'react-redux';
import { useEffect, lazy, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useParams } from "react-router-dom";
import Layout from './layout/Layout';
import { Admin } from './pages';
import { SET_USER } from "./reducer/Main.reducer";
import { useTranslation } from 'react-i18next';
import AuthUtils from './services/authService';
import { apiEndpoint } from './common/open2web3';
import Loading from './pages/Loading';
import TokenSale from './pages/TokenSale';
// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-223763373-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const TokenSaleLazy = lazy(() => import('./pages/TokenSale'));
const MonCompteLazy = lazy(() => import('./pages/MonCompte'));
const ProfileLazy = lazy(() => import('./pages/Profile'));
const VerifyAccountLazy = lazy(() => import('./pages/AccountVerification'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App({ store, dispatch }) {

    const { t, i18n } = useTranslation();

    const supportedLanguages = ["fr", "en"];


    useEffect(
        () => {
            let pathUrl = window.location.pathname.split("/")
            let pathLang = pathUrl[1];

            if (supportedLanguages.includes(pathLang)) {
                i18n.changeLanguage(pathLang)
                localStorage.setItem('lang', pathLang);
                if (AuthUtils.isConnected()) {
                    fetch(`${apiEndpoint}/services/myUsers/loggedInUserlanguage?lang=${pathLang}`, {
                        method: 'PUT',
                    }).catch(error => console.log(error));
                }
                if (pathUrl[2] == "") {
                    var redirectPath = "/" + pathLang + "/token-sale";
                    //window.location.href = redirectPath;
                    window.history.pushState("", "", redirectPath)
                }
            } else {
                var defaultLanguage = localStorage.getItem('lang');
                if (defaultLanguage == null) defaultLanguage = "en";
                var redirectPath = "/" + defaultLanguage + "/" + (pathUrl[2] == "" ? pathUrl[2] : 'token-sale')
                //window.location.href = redirectPath;
                window.history.pushState("", "", redirectPath)
            }

            let savedUser = localStorage.getItem('user');
            if (savedUser !== undefined && savedUser !== '' && savedUser !== 'undefined' && savedUser !== 'null') {
                let newUser = {
                    ...JSON.parse(savedUser),
                }
                if (!newUser?.username) return;
                dispatch({ type: SET_USER, payload: { user: newUser, walletConnected: true } })
            }
            
            return () => {

            };
        },
        []
    );

    // for compatibility old logique
    useEffect(() => {
        localStorage.setItem('lang', i18n.language);
    }, [i18n.language]);

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }, []);


    const connected = !!store.walletAddress || (!!store?.user?.username && store?.walletConnected);
    const isNotOwner = !store.owner || !store.walletAddress || store.owner !== store.walletAddress;



    return (
        <>
            <BrowserRouter>
                <Layout>
                    <Switch>
                        <Route exact path="/:lang/verify-account" component={VerifyAccountLazy} />
                        <Route exact path="/:lang/reset-password" component={ResetPassword} />
                        {/*<Route exact path="/" component={Dashboard} />*/}
                        {connected && isNotOwner ? <Route exact path="/:lang/referral" component={MonCompteLazy} /> : null}
                        {connected && isNotOwner ? <Route exact path="/:lang/myProfile" component={ProfileLazy} /> : null}
                        <Route path="/:lang/token-sale" component={TokenSale} />
                        <Route exact path="/loading" component={Loading} />
                        {/*<Route exact path="/properties" component={Properties} />*/}
                        {/* <Route exact path="/whitepaper" component={Whitepaper} />*/}
                        {isNotOwner ? null : <Route exact path="/:lang/admin" component={Admin} />}
                        {isNotOwner ? <Redirect to={'/'+i18n.language + "/token-sale"} /> : <Redirect to={"/"+i18n.language +"/admin"} />}
                        {/*isNotOwner ? <Redirect to="/token-sale" /> : <Redirect to="/admin" />*/}

                    </Switch>
                </Layout>

            </BrowserRouter>
        </>
    );
}

const mapState = (store) => ({ store });
const mapDispatch = (dispatch) => ({ dispatch });

export default connect(mapState, mapDispatch)(App);
