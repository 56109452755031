import { useTranslation } from 'react-i18next';
import React from 'react';
import i18n from '../i18n';

function NumberFormater(value){

    //const { i18n } = useTranslation();
    try {
        if(isNaN(value)){
            return null;
        }
    
        const  EnglishFormater = () => {
            return parseFloat(value).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
    
        const  FrenchFormater = () => {
            return parseFloat(value).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, " ").replace('.',',');
        }    
    
        switch(i18n.language){
            case 'fr':
                return FrenchFormater();
            default:
                return EnglishFormater();    
        }
    } catch (error) {
        console.error(error);
    }
    return value;
}

export default NumberFormater;